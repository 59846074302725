import { useState } from 'react';
import Router from 'next/router';
import { useCookies } from 'react-cookie';

import { timeConverter } from '../../helpers/jwt';
import { useStore as authStore } from '../../contexts/auth';
import { useStore as historyStore } from '../../contexts/history';
import requestLogout from '../../requests/mutation/auth/logout';
import requestRefreshAccessToken from '../../requests/mutation/auth/refresh-access-token';
import requestUser from '../../requests/query/profile/user';
import requestContact from '../../requests/query/profile/contact';
import useAPI from './useAPI';
import useRedirect from './useRedirect';
import useApp from './useApp';
import useJWT from './useJWT';

// https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/

const useAuth = () => {
	const localStorageNm = 'access_token';
	const [, setCookie, removeCookie] = useCookies([localStorageNm]);

	const [isDev] = useState(process.env.NODE_ENV === 'development');

	const { isJwtTokenValidClient, parseJwt, getJWTData } = useJWT();
	const { query, mutate } = useAPI();
	const { redirect } = useRedirect();
	const { prevRoute } = historyStore();

	const [isSSR] = useState(typeof window === 'undefined');
	const { appDispatch } = useApp();
	const { data: authData, dispatch } = authStore();
	const { authenticated } = authData;

	const { userData, contactData } = authData;

	// Local storage token handle

	const saveAccessToken = (token) => {
		if (isSSR === false) {
			window.localStorage.setItem(localStorageNm, token);
			setCookie(localStorageNm, token, { path: '/' });
		}
	};

	const unsetAccessToken = () => {
		if (isSSR === false) {
			window.localStorage.removeItem(localStorageNm);
			removeCookie(localStorageNm, { path: '/' });
		}
	};

	const getAccessToken = () => {
		const token = isSSR === false ? window.localStorage.getItem(localStorageNm) : null;

		if (token) {
			if (isJwtTokenValidClient(token)) {
				return token;
			}

			// If not valid - unset!
			unsetAccessToken();
		}

		return null;
	};

	// Login or "refresh token"
	const login = (token, redirectBack = false, forceLoadProfiledata = false) => {
		const t = token || getAccessToken();

		if (t) {
			// Local storage/cookie handle
			saveAccessToken(t);

			// Global state handle
			dispatch({
				type: 'LOGIN',
				payload: {
					token: t
				}
			});

			// Load user/contact = profile data
			loadProfileData(forceLoadProfiledata, getJWTData(t));
		} else {
			dispatch({ type: 'NOT_AUTHENTICATED' });
		}

		if (redirectBack) {
			const lastRoute = prevRoute();
			Router.push((!lastRoute || (lastRoute.indexOf('forgot-password') !== -1 || lastRoute.indexOf('register') !== -1 || lastRoute.indexOf('login') !== -1)) ? '/' : lastRoute);
		}

		return false;
	};

	const refreshAccessToken = () => {
		mutate(requestRefreshAccessToken, { access_token: getAccessToken() }, (d, e) => {
			if (d && d.access_token) {
				const token = d.access_token;

				// Local storage handle
				saveAccessToken(token);

				// Global state handle
				dispatch({
					type: 'REFRESH_TOKEN',
					payload: {
						token
					}
				});

				// Re-load user/contact = profile data
				loadProfileData(true, getJWTData(token));
			}
		});
	};

	// Login & logout

	const logout = () => {
		const token = getAccessToken();

		if (token) {
			// Server logout
			mutate(requestLogout, { access_token: token }, (d, e) => {
				if (isDev) console.log('User logged out.');
			});

			// Local storage handle
			unsetAccessToken(token);

			// Destroy user & contact data in context
			dispatch({ type: 'SET_USER_DATA', payload: {} });
			dispatch({ type: 'SET_CONTACT_DATA', payload: {} });

			// Reset layout (on mobile), when user profile & logout button is @ overlay
			appDispatch({ type: 'SET_OVERLAY_PANEL', payload: null });
		}

		// Global state handle
		dispatch({
			type: 'LOGOUT'
		});
	};

	// User data

	const loginExpirationDtm = () => {
		const token = getAccessToken();

		if (token) {
			const d = parseJwt(token);
			return (d && d.exp) ? timeConverter(d.exp) : null;
		}

		return null;
	};

	const getUser = () => {
		if (userData) return userData;
		return {};
	};

	const loadData = (type, id, callback) => {
		let rowId = id;
		const fieldName = `${type}_id`;

		// Currently logged in user
		if (rowId === null || rowId === 'undefined') {
			rowId = authData.data[fieldName];
		}

		query(type === 'user' ? requestUser : requestContact, { [fieldName]: rowId }, callback);
	};

	const logoutIfTokenInvalid = (e) => {
		// If users token is invalid after server validation - logout
		if (e.graphQLErrors && e.graphQLErrors[0].potError.errorCode === 'not-authenticated') {
			logout();
			if (isDev) console.log('User is forced logout. Token is not valid on server.');
		}
	};

	const loadUser = (id) => {
		loadData('user', id, (d, e) => {
			if (d) dispatch({ type: 'SET_USER_DATA', payload: d });
			if (e) logoutIfTokenInvalid(e);
		});
	};

	const loadContact = (id) => {
		loadData('contact', id, (d, e) => {
			if (d) dispatch({ type: 'SET_CONTACT_DATA', payload: d });
			if (e) logoutIfTokenInvalid(e);
		});
	};

	const reloadProfiledata = (sections) => {
		const jwtData = getJWTData(authData.token);

		if (sections.indexOf('user') !== -1 && jwtData.user_id) loadUser(jwtData.user_id);
		if (sections.indexOf('contact') !== -1 && jwtData.contact_id) loadContact(jwtData.contact_id);
	};

	const loadProfileData = (force = false, aData = {}) => {
		if (force === true || (Object.keys(userData).length === 0 && userData.constructor === Object)) {
			loadUser(aData.user_id);
		}

		if (force === true || (Object.keys(contactData).length === 0 && contactData.constructor === Object)) {
			loadContact(aData.contact_id);
		}
	};

	// Quick view

	const openQuickView = (step, callback, onAuthenticated) => {
		if (!authenticated) {
			dispatch({ type: 'QUICK_VIEW_SET_STEP', payload: { step, callback } });
			return true;
		}

		if (onAuthenticated) {
			if (typeof onAuthenticated === 'function') {
				onAuthenticated();
			} else if (onAuthenticated.indexOf('redirect:') !== -1) { // ex. redirect:pricelist => /cenik
				redirect(onAuthenticated.split(':')[1] || null);
			}
		} else {
			callback();
		}

		return true;
	};

	return { authData, authenticated, userData, contactData, dispatch, login, logout, getAccessToken, refreshAccessToken, reloadProfiledata, getUser, loginExpirationDtm, openQuickView, logoutIfTokenInvalid };
};

export default useAuth;
