import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'auth>user_token_verify'
	},

	data: null,

	gql: {
		schema: gql`
      query UserTokenVerify(
      	$token: String
      ) {
      	request(
      		token: $token
      	) {
      		status
      	}
      }
		`
	}
};

export default request;
