import createApolloClient from '../../apollo/client';

import { setContext } from '../../helpers/gql';

const useAPI = () => {
	const API = (type, request, variables, callback, options) => {
		const client = createApolloClient();

		const r = {
			[type === 'query' ? 'query' : 'mutation']: request.gql.schema,
			variables,
			context: setContext(request.properties.name)
		};

		if (typeof options !== 'undefined') {
			// On Query only!
			if (options.fetchPolicy) { // https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
				r.fetchPolicy = options.fetchPolicy; // 'network-only' // no cache!
			}
		}

		client[type](r)
			.then(result => callback(result.data.request, result.errors ? result.errors[0].potError : null))
			.catch((error) => {
				if (typeof callback === 'function') {
					callback(null, error);
				}
			});

		return client;
	};

	const query = (request, variables, callback, options) => {
		return API('query', request, variables, callback, options);
	};

	const mutate = (request, variables, callback, options) => {
		return API('mutate', request, variables, callback, options);
	};

	return { query, mutate };
};

export default useAPI;
