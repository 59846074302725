import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'auth>refresh_access_token'
	},

	validation: {},

	gql: {
		schema: gql`
			mutation RefreshAccessToken(
			  $access_token: String
			) {
			  request(
			    access_token: $access_token
			  ) {
			    access_token
			  }
			}
		`
	}
};

export default request;
