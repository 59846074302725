import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'auth>logout'
	},

	validation: {},

	gql: {
		schema: gql`
			mutation Logout(
			  $access_token: String!,
			) {
			  request(
			    access_token: $access_token,
			  ) {
			    row_id
			  }
			}
		`
	}
};

export default request;
