import { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';

// import requestJwt from '../../requests/query/auth/user_token_verify';
import useAPI from './useAPI';

const useJWT = () => {
	const { mutate } = useAPI();

	const [accessTokenNm] = useState('access_token');
	const [cookies] = useCookies([accessTokenNm]);

	const getJWTToken = () => typeof window === 'undefined' ? cookies[accessTokenNm] : window.localStorage.getItem(accessTokenNm);

	const parseJwt = () => {
		const token = getJWTToken();

		if (token) {
			return jwt_decode(token);
		}

		return null;
	};

	const getJWTData = (token) => {
		if (token) {
			const d = parseJwt(token);

			if (d !== null && d.data) {
				return d.data;
			}
		}

		return {};
	};

	const isJwtTokenValidClient = () => {
		const token = parseJwt();

		if (token !== null) { // token exist, but must be validated
			if (!token.exp) return false; // token is "broken"

			// Check, if token expired
			const currentDate = new Date();
			return token.exp * 1000 > currentDate.getTime();
		}

		return null; // token not exist, user not authorized.
	};

	const isJwtTokenValidServer = (callback) => {
		mutate(require('../../requests/query/auth/user_token_verify.js').default, { token: getJWTToken() }, (d, e) => callback(d && d.status === true));
	}

	return { getJWTToken, parseJwt, getJWTData, isJwtTokenValidClient, isJwtTokenValidServer };
};

export default useJWT;
